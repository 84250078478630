import { useEffect, useState } from 'react';

let getHeight: null | Function = null;
if (typeof window !== 'undefined')
  getHeight = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

export function useWindowHeight() {
  const [height, setHeight] = useState(getHeight && getHeight());

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setHeight(getHeight && getHeight()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return height;
}

export default useWindowHeight;
