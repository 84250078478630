import { Box, Flex } from 'juhuui';
import React, { useEffect, useState } from 'react';
import { a, config, useSpring } from 'react-spring';
import { useScroll } from 'react-use-gesture';
import useMeasure from 'react-use-measure';

import { useWindowHeight } from '../hooks';
// import { Animated } from '../styles';
import { window } from '../utilities';
import Image from './image';

const RefBox = Box.with();

const VerticalDivideColor = () => (
  <>
    <Box
      css={{
        bg: 'tom.bg',
        h: '50%',
        left: '0',
        pos: 'absolute',
        top: '0',
        w: 'full',
      }}
    />
    <Box
      css={{
        bg: 'tom.bgBright',
        h: '50%',
        left: '0',
        pos: 'absolute',
        top: '50%',
        w: 'full',
      }}
    />
  </>
);

const Animated = Box.with({
  as: a.div,
  pos: 'absolute',
  h: 'full',
  willChange: 'transform',
});

const OverlayVerticalDivideColor = ({ left = true, animatedWidth }) => {
  const windowHeight = useWindowHeight();

  const [leftSliderProps, setAnimation] = useSpring(() => ({
    x: left ? '-2px' : '2px',
    config: config.molasses,
  }));

  useScroll(
    ({ xy: [, y] }) => {
      const done = y / (windowHeight / 3);
      if (done > 1) return;
      setAnimation({
        x: left ? `${animatedWidth * done}px` : `-${animatedWidth * done}px`,
      });
    },
    { domTarget: window }
  );

  return (
    <Animated
      css={{
        w: `${animatedWidth}px`,
        ...(left
          ? { left: `-${animatedWidth}px` }
          : { right: `-${animatedWidth}px` }),
      }}
      style={leftSliderProps}
    >
      <VerticalDivideColor />
    </Animated>
  );
};

function HeroAnimated({ src, alt }) {
  const [refContainer, { width: containerWidth }] = useMeasure();
  const [refFrame, { width: frameWidth }] = useMeasure();
  const [animatedWidth, setAnimatedWidth] = useState(0);

  useEffect(() => {
    setAnimatedWidth((frameWidth - containerWidth) / 2 - 2);
  }, [containerWidth, frameWidth]);

  return (
    <>
      <RefBox
        ref={refContainer}
        css={{
          maxW: 'var(--max-width)',
          w: 'calc(100% - (2 * var(--px)))',
        }}
      />
      <RefBox ref={refFrame} css={{ pos: 'relative', zIndex: 3 }}>
        {/* <VerticalDivideColor /> */}
        <Flex
          css={{
            direction: 'column',
            margin: 'auto',
            overflow: 'hidden',
            pos: 'relative',
            w: 'full',
          }}
        >
          <Image
            alt={alt}
            h={['50vh', undefined, 'initial']}
            image={src}
            loading="eager"
          />
          <OverlayVerticalDivideColor animatedWidth={animatedWidth} />
          <OverlayVerticalDivideColor
            animatedWidth={animatedWidth}
            left={false}
          />
        </Flex>
      </RefBox>
    </>
  );
}

export default HeroAnimated;
